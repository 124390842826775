<!-- 学科数据 -->
<template>
    <div class="subjectData">
        <el-form ref="form" :model="form" inline class="form"  label-width="80px">
           <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入关键字"></el-input>
            </el-form-item>
           <!-- <el-form-item label="名称">
                <el-input v-model="form.name" placeholder="请输入名称"></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="学科类">
                <el-select v-model="form.discipline" placeholder="请选择" :clearable="true">
                    <el-option
                  v-for="item in form.disciplineChoose"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
                </el-select>
            </el-form-item> -->
          <!--  <el-form-item label="状态">
                <el-select v-model="form.condition" placeholder="请选择" :clearable="true">
                    <el-option
                  v-for="item in form.conditionChoose"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item label="" style="margin-left: 20px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="close">
                    重置
                </el-button>
            </el-form-item>
        </el-form>
        <div class="" style="margin-left:22px ;">
            <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
            <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;"
                @click="newlyAdded">
                新增
            </el-button>
            <!-- <el-button size="small" class="daochu" >
                导出
            </el-button> -->
        </div>
        <!-- 表格部分 -->
        <el-table :data="tableData"  border stripe style="width:97.9%;flex: 1;" height="650" 
        id="out-table" @sort-change="abc">
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column prop="course_number" sortable="custom" label="学科编号" align="center" width="120">
            </el-table-column>
            <el-table-column prop="mcode" label="助记码" sortable="custom" align="center"  width="150">
            </el-table-column>
            <el-table-column prop="course_name" label="学科名称" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="course_allname" label="学科全称" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="course_abbreviation" label="简称" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center" sortable="custom" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==1">可用</span>
                    <span v-else-if="scope.row.status==2">停用</span>
                </template>
            </el-table-column>
            <el-table-column prop="pid" label="上级学科" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="course_style1" label="学科类1" sortable="custom" align="center" width="150">
                <template slot-scope="scope">
                    <span v-if="scope.row.course_style1==1">考级</span>
                    <span v-else-if="scope.row.course_style1==2">培训</span>
                    <span v-else-if="scope.row.course_style1==3">其他</span>
                </template>
            </el-table-column>
            <el-table-column prop="course_style2" label="学科类2" sortable="custom" align="center" width="150">
                <template slot-scope="scope">
                    <span v-if="scope.row.course_style2==1">少儿</span>
                    <span v-else-if="scope.row.course_style2==2">成人</span>
                    <span v-else-if="scope.row.course_style2==3">考级</span>
                </template>
            </el-table-column>
            <el-table-column prop="remark" label="说明" align="center" sortable="custom" width="300"
             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column  label="操作"  align="center" width="200" fixed="right">
                <template slot-scope="scope">
                    <el-button icon="el-icon-edit" size="mini" 
                    style="background: #18BC9C;color:#FFFFFF;" @click="edit(scope.row.id)">
                        编辑
                    </el-button>
                    <el-button type="danger" icon="el-icon-delete" size="mini" @click = "dlt(scope.$index, scope.row.id)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50, 100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                    number:"",//编号
                    name:"",//名称
                    discipline:"",//学科类
                    disciplineChoose:[],//学科类选择
                    condition:"",//状态
                    conditionChoose:[],//状态选择
                    search:""
                },
                tableData: [],
                currentPage: 1,
				currentLimit: 20,
                total: 0,
            }
        },
        created() {
            this.getList()
        },
        activated() {
           // console.log(1); 
           this.getList()
        },
        methods:{
			getList() {
				this.$request({url: '/api/course/list', method: 'POST', data: {
					page: this.currentPage,
					limit: this.currentLimit,
					course_number: this.form.number,
					course_name: this.form.name,
					pid: this.form.discipline,
					status: this.form.condition,
                    fullname:this.form.search
				}}).then(res => {
					console.log(res)
					this.tableData = res.data.list
					this.total =res.data.count
				})
			},
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            	this.currentLimit = val
            	this.getList()
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.currentPage = val
            	this.getList()
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "addSubjectData"
                })
            },
            edit(id) { //编辑
                this.$router.push({
                    path: "editSubjectData",
					query:{ id: id}
                })
            },
			// 查询
			query() {
                this.currentPage=1
				this.getList()
			},
            close(){//重置
                this.form.search=''
                this.getList()
            },
			// 删除
			dlt(index, id) {
				var that = this
				this.$confirm('是否删除此条记录?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					that.$request({url: '/api/course/del', method: 'POST', data: {id}}).then(res => {
						if(res.code == 1) {
							that.$message({
							  message: res.msg,
							  type: 'success'
							});
							that.tableData.splice(index, 1) 
						}
					})
				})
			},
            //排序
            abc(i){ 
                console.log(i)
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr,111)
                this.getList()
            }
        }
    }
</script>

<style scoped="scoped">
    .subjectData{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 查询部分 */
    .form {
         padding-top: 34px;
    }
    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    /* 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    
    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }
    
    ::v-deep .el-icon-download {
        font-weight: bold !important;
    }
    .daochu{
        padding-left: 30px;
        background: url(../../assets/export.png) 8px 5px no-repeat #FF7300;
        color: #FFFFFF;
    }
    /* 表格部分 */
    .el-table {
        margin: 20px;
    }
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
